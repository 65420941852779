import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import '../../css/Snackbar.css';
import zIndex from '@mui/material/styles/zIndex';




const Alert = React.forwardRef(function Alert(props, ref) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionUp(props) {
   return <Slide {...props} direction="up" />;
}

export default function SnackbarAlert({ open, handleClose, msg, severity, backgroundColor, autoHide }) {
   const action = (
      <Button color="white" size="small">
         Close
      </Button>
   );

   const [hideDuration, setHideDuration] =  React.useState(4000)

   React.useEffect(() => {
      autoHide && setHideDuration(autoHide)
   },[autoHide])

   return (
      <div>
         <Snackbar
            sx={{ display: 'flex', marginBottom: '50px', padding: 'auto 30px auto 30px', zIndex: 99999}}
            open={open}
            autoHideDuration={severity === 'error' ? null : hideDuration}
            onClose={handleClose}
            TransitionComponent={TransitionUp}
            // action={action}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "center"
            }}

         >
            <Alert severity={severity} sx={{ width: '400px', maxWidth: '1400px', minWidth: '300px', zIndex: 9999, backgroundColor: backgroundColor,
               position: 'relative'}}>
               <p style={{marginLeft: '0px', marginRight: '30px', marginTop: '0px', marginBottom: '0px', padding: '0px', color: '#fff'}}>{msg}</p>
               <IconButton
               size="small"
               aria-label="close"
               color="inherit"
               onClick={handleClose}
               sx={{
                  position: 'absolute',
                  right: '10px',
                  marginTop: '0px',
                  padding: '0px',
                  top:'15px',
                  marginRight: '10px',
               }}
            >
               <CloseIcon fontSize="small" />
            </IconButton></Alert>

         </Snackbar>
      </div>
   );
}