import React, {Component, lazy, Suspense} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import SnackbarAlert from './components/common/SnackbarAlert';
import { withRouter } from './components/common/common/withRouter';
import { setSnackConfig } from './redux/slice/InfluencerSlice';


const StripeRedirect = lazy(() => import('./components/Pages/StripeRedirect'));

const Home = lazy(() => import('./components/Home/Home'));
const UserLogin = lazy(() => import('./components/Login_Register/UserLogin'));
const UserRegister = lazy(() => import('./components/Login_Register/UserRegister'));
const UserRegisterRedirect = lazy(() => import('./components/Login_Register/UserRegisterRedirect'));



const CompleteProfile = lazy(() => import('./components/Login_Register/CompleteProfile'));
// const Content = lazy(() => import('./components/Content/Content'));
const Influencer = lazy(() => import('./components/Influencer/Influencer'));
const Settings = lazy(() => import('./components/Settings_Profile/Settings'));
const VerifyEmail = lazy(() => import("./components/Emails/VerifyEmail"));
const AllTermsConditions = lazy(() => import("./components/legal/AllTermsConditions"));
const CookiePolicy = lazy(() => import("./components/legal/CookiePolicy"));
const PrivacyPolicy = lazy(() => import("./components/legal/PrivacyPolicy"));
const CompleteAuthentication = lazy(() => import('./components/Login_Register/CompleteAuthentication'));
// const LandingPage = lazy(() => import('./components/SelfServe/LandingPage'));
const SalesPage = lazy(() => import('./components/SelfServe/SalesPage'));
const PricingPage = lazy(() => import('./components/SelfServe/PricingPage'));
const GuideLandingPage = lazy(() => import('./components/SelfServe/GuideLandingPage'));
const CheckoutRedirect = lazy(() => import('./components/Influencer/CheckoutRedirect'));


const ForgotPassword = lazy(() => import("./components/Login_Register/Forgotten_Forms/ForgotPassword"));
const EmailConfirmation = lazy(() => import("./components/Login_Register/Forgotten_Forms/EmailConfirmation"));
const PasswordReset = lazy(() => import("./components/Login_Register/Forgotten_Forms/PasswordReset"));
const PasswordChanged = lazy(() => import("./components/Login_Register/Forgotten_Forms/PasswordChanged"));
const ImagePostType = lazy(() => import("./components/Content/content_upload_type/ImagePostType"));
const VideoPostType = lazy(() => import("./components/Content/content_upload_type/VideoPostType"));
const AudioPostType = lazy(() => import("./components/Content/content_upload_type/AudioPostType"));
const PdfPostType = lazy(() => import("./components/Content/content_upload_type/PdfPostType"));
const TextPostType = lazy(() => import("./components/Content/content_upload_type/TextPostType"));
const LivePostType = lazy(() => import("./components/Content/content_upload_type/LivePostType"));

const EditPost = lazy(() => import("./components/Content/EditPost"));
const Staff = lazy(() => import('./components/Staff/Staff'));
const StaffInfluencerApprovalPortal = lazy(() => import("./components/Staff/StaffInfluencerApprovalPortal"));
const StaffAllAnalytics = lazy(() => import("./components/Staff/StaffAllAnalytics"));
const OptOutEmail = lazy(() => import('./components/Emails/OptOutEmail'));
const LivePostBroadcast = lazy(() => import("./components/Content/LivePostBroadcast"));
const HomeDiscover = lazy(() => import("./components/Home/HomeDiscover"));
// const CreatorSurvey= lazy(() => import('./components/SelfServe/CreatorSurvey'));
const BugReport = lazy(() => import('./components/SelfServe/BugReport'));
// const UserSurvey = lazy(() => import('./components/Home/UserSurvey'));
const SelfServeMaster = lazy(() => import('./components/SelfServe/OnboardingComponents/SelfServeMaster'));
const EmailLoginRedirect = lazy(() => import('./components/Influencer/EmailLoginRedirect'));
const OpenApp = lazy(() => import('./components/Influencer/OpenApp'));
const StripePricingTable = lazy(() => import('./components/Influencer/StripePricingTable'));
// Onboarding


// const INTERCOM_APP_ID = 'lfsiny3p';

class App extends Component {
   onRouteChanged() {
      // console.log("ROUTE CHANGED");
    }
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        this.onRouteChanged();
      }
      const snackConfig = this.props.influencerSlice.snackConfig;
    }
    componentWillUnmount() {
      // console.log("ROUTE CHANGED");
      const __DEV__ = window.location.hostname === 'localhost';
      window.__DEV__ = __DEV__;
    }
   render() {

      const snackConfig = this.props.influencerSlice.snackConfig;

      return (
         <>
            {/* <IntercomProvider appId={INTERCOM_APP_ID} > */}
            {/*<ThemeProvider*/}
            {/*   options={{*/}
            {/*      theme: 'light',*/}
            {/*      save: true,*/}
            {/*   }}*/}
            {/*>*/}
            <Routes>
               <Route sensitive={false} path="/" element={<Suspense fallback={<></>}><Home /></Suspense>} />
               <Route sensitive={false} path="/discover" element={<Suspense fallback={<></>}><HomeDiscover /></Suspense>} />
               <Route sensitive={false} path="/login" element={<Suspense fallback={<></>}><UserLogin /></Suspense>} />
               {/* Will rename once everything is ready to go */}
               {/* <Route path="/login-rebrand" element={<UserLogin/>}/>
                  <Route path="/register-rebrand" element={<UserRegister/>}/> */}

               {/* <Route path="/register_influencer" element={<Register influencer_bool={true}/>}/> */}
               <Route sensitive={false} path="/register" element={<Suspense fallback={<></>}><UserRegister /></Suspense>} />
               <Route sensitive={false} path="/register_redirect" element={<Suspense fallback={<></>}><UserRegisterRedirect /></Suspense>} />
               <Route sensitive={false} path="/checkout_redirect" element={<Suspense fallback={<></>}><CheckoutRedirect /></Suspense>} />
               <Route sensitive={false} path="/forgot_password" element={<Suspense fallback={<></>}><ForgotPassword /></Suspense>} />
               <Route sensitive={false} path="/email_confirmation" element={<Suspense fallback={<></>}><EmailConfirmation /></Suspense>} />
               <Route sensitive={false} path="/password_reset" element={<Suspense fallback={<></>}><PasswordReset /></Suspense>} />
               <Route sensitive={false} path="/password_changed" element={<Suspense fallback={<></>}><PasswordChanged /></Suspense>} />
               <Route sensitive={false} path="/terms_and_conditions" element={<Suspense fallback={<></>}><AllTermsConditions /></Suspense>} />
               <Route sensitive={false} path="/cookie_policy" element={<Suspense fallback={<></>}><CookiePolicy /></Suspense>} />
               <Route sensitive={false} path="/privacy_policy" element={<Suspense fallback={<></>}><PrivacyPolicy /></Suspense>} />
               <Route sensitive={false} path="/opt_out" element={<Suspense fallback={<></>}><OptOutEmail /></Suspense>} />
               <Route sensitive={false} path="/help" element={<Suspense fallback={<></>}><HomeDiscover help_open={true} /></Suspense>} />
               {/* <Route sensitive={false} path="/help_improve_withme" element={<Suspense fallback={<></>}><CreatorSurvey/></Suspense>}/> */}
               <Route sensitive={false} path="/report_a_bug" element={<Suspense fallback={<></>}><BugReport /></Suspense>} />
               {/* <Route sensitive={false} path="/improve_withme" element={<Suspense fallback={<></>}><UserSurvey/></Suspense>}/> */}
               {/* <Route path="/blog_index" element={<Blog_index/>}/> */}
               {/* <Route path="/blogs/:blog_id" element={<Blog_post_page/>}/> */}
               {/*<Route path="/newpost" element={<InfluencerUpload/>}/>*/}
               {/*<Route path="/influencer_register" element={<InfluencerRegisterStepper/>}>*/}
               {/*   <Route path=":step" element={<TextMobileStepper/>} />*/}
               {/*</Route>*/}


               {/* Self Serve` */}
               <Route sensitive={false} path="/become-a-creator" element={<Suspense fallback={<></>}><SalesPage /></Suspense>} />
               <Route sensitive={false} path="/pricing" element={<Suspense fallback={<></>}><PricingPage /></Suspense>} />
               <Route sensitive={false} path="/pricing-table" element={<Suspense fallback={<></>}><StripePricingTable /></Suspense>} />

               <Route sensitive={false} path="/building-a-membership-guide" element={<Suspense fallback={<></>}><GuideLandingPage /></Suspense>} />

               {/* <Route sensitive={false} path="/influencer/dashboard" element={<Dashboard/>}/> */}
               {/* <Route sensitive={false} path="/influencer/dashboard/:content_id" element={<Suspense fallback={<></>}><Influencer dashboard={true}/></Suspense>}/>
                  <Route sensitive={false} path="/influencer/dashboard" element={<Suspense fallback={<></>}><Influencer dashboard={true}/></Suspense>}/> */}
               {/*<Route path="/become-a-creator/register" element={<InfluencerRegister/>}/>*/}
               {/* <Route path="/become-a-creator" element={<InfluencerStepper/>} /> */}
               {/* <Route path="/become-a-creator/register" element={<SelfServe0Register/>} />
                  <Route path="/become-a-creator/welcome" element={<SelfServe1Welcome/>} />
                  <Route path="/become-a-creator/your-experience" element={<SelfServe2Question/>} />
                  // {/* <Route path="/become-a-creator/profile-details" element={<SelfServe3ProfileDetails/>} />
                  <Route path="/become-a-creator/profile-images" element={<SelfServe4ProfileImages/>} />
                  <Route path="/become-a-creator/profile-video" element={<SelfServe5ProfileVideo/>} />
                  <Route path="/become-a-creator/content" element={<SelfServe6ContentQuestion/>} />
                  <Route path="/become-a-creator/create-tiers" element={<SelfServe7Tiers/>} />
                  <Route path="/become-a-creator/lets-add-content" element={<SelfServe8ContentUpload/>} /> */}
               <Route sensitive={false} path="/become-a-creator/register" element={<Suspense fallback={<></>}><SelfServeMaster current_page={'register'} /></Suspense>} />
               <Route sensitive={false} path="/become-a-creator/welcome" element={<Suspense fallback={<></>}><SelfServeMaster current_page={'welcome'} /></Suspense>} />
               <Route sensitive={false} path="/become-a-creator/your-experience" element={<Suspense fallback={<></>}><SelfServeMaster current_page={'your-experience'} /></Suspense>} />
               <Route sensitive={false} path="/become-a-creator/start-free-trial" element={<Suspense fallback={<></>}><SelfServeMaster current_page={'start-free-trial'} /></Suspense>} />
               <Route sensitive={false} path="/become-a-creator/personalise-your-domain" element={<Suspense fallback={<></>}><SelfServeMaster current_page={'personalise-your-domain'} /></Suspense>} />
               <Route sensitive={false} path="/become-a-creator/tell-us-about-yourself" element={<Suspense fallback={<></>}><SelfServeMaster current_page={'tell-us-about-yourself'} /></Suspense>} />

               <Route sensitive={false} path="/become-a-creator/profile-details" element={<Suspense fallback={<></>}><SelfServeMaster current_page={'profile-details'} /></Suspense>} />
               <Route sensitive={false} path="/become-a-creator/profile-images" element={<Suspense fallback={<></>}><SelfServeMaster current_page={'profile-images'} /></Suspense>} />
               <Route sensitive={false} path="/become-a-creator/profile-video" element={<Suspense fallback={<></>}><SelfServeMaster current_page={'profile-video'} /></Suspense>} />
               <Route sensitive={false} path="/become-a-creator/content" element={<Suspense fallback={<></>}><SelfServeMaster current_page={'content'} /></Suspense>} />
               {/* Todo- when page is added, add Helmet to it for dynamic page titles */}
               <Route sensitive={false} path="/become-a-creator/create-tiers" element={<Suspense fallback={<></>}><SelfServeMaster current_page={'create-tiers'} /></Suspense>} />
               <Route sensitive={false} path="/become-a-creator/lets-add-content" element={<Suspense fallback={<></>}><SelfServeMaster current_page={'lets-add-content'} /></Suspense>} />
               <Route sensitive={false} path="/email_login_redirect" element={<Suspense fallback={<></>}><EmailLoginRedirect /></Suspense>} />
               <Route sensitive={false} path="/email_login_redirect_app" element={<Suspense fallback={<></>}><EmailLoginRedirect /></Suspense>} />
               <Route sensitive={false} path="/open_app" element={<Suspense fallback={<></>}><OpenApp /></Suspense>} />
               {/*<Route path="/become-a-creator/0" element={<Register0/>} />*/}
               {/*<Route path="/become-a-creator/0" element={<Register0/>} />*/}
               {/*<Route path="/become-a-creator/0" element={<Register0/>} />*/}
               {/*<Route path="/become-a-creator/0" element={<Register0/>} />*/}
               {/*<Route path="/become-a-creator/0" element={<Register0/>} />*/}





               {/*<Route path="/become-a-creator" element={<InfluencerRegisterStepper/>}>*/}
               {/*   <Route path=":step" element={<TextMobileStepper/>} />*/}
               {/*</Route>*/}


               {/* Upload Routes */}
               <Route sensitive={false} path="/upload" element={<Suspense fallback={<></>}><ImagePostType /></Suspense>} />
               <Route sensitive={false} path="/upload/image" element={<Suspense fallback={<></>}><ImagePostType /></Suspense>} />
               <Route sensitive={false} path="/upload/video" element={<Suspense fallback={<></>}><VideoPostType /></Suspense>} />
               <Route sensitive={false} path="/upload/audio" element={<Suspense fallback={<></>}><AudioPostType /></Suspense>} />
               <Route sensitive={false} path="/upload/pdf" element={<Suspense fallback={<></>}><PdfPostType /></Suspense>} />
               <Route sensitive={false} path="/upload/text" element={<Suspense fallback={<></>}><TextPostType /></Suspense>} />
               <Route sensitive={false} path="/upload/live" element={<Suspense fallback={<></>}><LivePostType /></Suspense>} />
               <Route sensitive={false} path="/upload/live/:influencer_name/:content_id" element={<Suspense fallback={<></>}><LivePostBroadcast /></Suspense>} />
               {/* Upload Routes */}
               {/*<Route path="/content_upload/:upload_type" element={<Content_Upload/>}/>*/}
               <Route sensitive={false} path="/edit/content/:post_id" element={<Suspense fallback={<></>}><EditPost /></Suspense>} />
               <Route sensitive={false} path="/login/complete-profile" element={<Suspense fallback={<></>}><CompleteProfile /></Suspense>} />
               <Route sensitive={false} path="/completing_authentication" element={<Suspense fallback={<></>}><CompleteAuthentication /></Suspense>} />
               <Route sensitive={false} path="/stripe_redirect" element={<Suspense fallback={<></>}><StripeRedirect /></Suspense>} />
               <Route sensitive={false} path="/settings" element={<Suspense fallback={<></>}><Settings /></Suspense>} />
               <Route sensitive={false} path="/verify_email" element={<Suspense fallback={<></>}><VerifyEmail /></Suspense>} />
               {/* <Route path="/contact" element={<ContactUs/>}/> */}
               {/* <Route path="/contact_influencer_register" element={<ContactUs reason={'influencer'}/>}/> */}
               <Route sensitive={false} path="/:influencer_name" element={<Suspense fallback={<></>}><Influencer /></Suspense>} />
               <Route sensitive={false} path="/:influencer_name/:content_id" element={<Suspense fallback={<></>}><Influencer /></Suspense>} />




               {/* <Route path="/reset_password" element={<ForgottenPassword/>}/> */}
               {/*<Route path="/contact" element={<ContactUs/>}/>*/}
               <Route sensitive={false} path="/staff" element={<Suspense fallback={<></>}><Staff /></Suspense>}>
                  <Route sensitive={false} path="influencers/await_approval" element={<Suspense fallback={<></>}><StaffInfluencerApprovalPortal /></Suspense>} />
                  <Route sensitive={false} path="analytics" element={<Suspense fallback={<></>}><StaffAllAnalytics /></Suspense>} />
               </Route>
            </Routes>
            {/*</ThemeProvider>*/}
            {/* </IntercomProvider> */}


            <SnackbarAlert
               msg={snackConfig.message}
               open={snackConfig.open}
               // severity={snackConfig.severity}
               backgroundColor={snackConfig.bgColor ?? 'var(--brand-color)'}
               autoHide={snackConfig.timeout ?? 4000}
               handleClose={() =>
                  this.props.dispatch(setSnackConfig({ open: false }))
               }
            />
         </>
      );
   }
}

export default withRouter(App);