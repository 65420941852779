import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
// import authApiSlice from './slice/AuthApiSlice';
// import userSlice from './slice/UserSlice';
import influencerSlice from '../slice/InfluencerSlice';

const reducer = combineReducers({
  // userSlice,
  // authApiSlice,
  influencerSlice,
});

export const reduxStore = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
