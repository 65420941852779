import React, {} from "react"
import {useParams, useNavigate, useLocation, useSearchParams,} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const withRouter = (WrappedComponent) => (props) => {
   const params = useParams();
   const location = useLocation();
   const hash = location.hash;
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();
   const { influencerSlice } = useSelector(state => state);
   const dispatch = useDispatch();


   const current_page_view = searchParams.get("page");

   return (
      <WrappedComponent
         {...props}
         params={params}
         navigate={navigate}
         influencerSlice={influencerSlice}
         dispatch={dispatch}
         location={location}
         searchParams={searchParams}
         current_page_view={current_page_view}
         hash={hash}
      />
   );
};

export { withRouter };