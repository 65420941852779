import React, { useState, useEffect } from 'react';
import { Box, Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSearchParams } from 'react-router-dom';

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [searchParams] = useSearchParams();
    const current_page_view = searchParams.get("page");

    const allowedPages = ['community', 'messages', '1v1coaching', 'subscriptions', 'courses', 'digital_products'];

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (!isVisible || !allowedPages.includes(current_page_view)) {
        return null;
    }

    return (
        <Box>
            <Fab
                sx={{
                    position: 'fixed',
                    bottom: '80px',
                    right: '20px',
                    zIndex: 1000,
                    color: '#fff',
                    backgroundColor: 'var(--brand-color)',
                    '&:hover': {
                        backgroundColor: 'var(--brand-color-hover)',
                    },
                    boxShadow: 'none'
                }}
                onClick={scrollToTop}
                aria-label="Scroll to top"
                color="primary"
                size="medium"
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Box>
    );
};

export default ScrollToTop;