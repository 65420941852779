import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  displayName: '',
  currentTab: 'home',
  influencerInfo: {},
  influencerInfoFirst: {},
  subscriptionSubTab: '',
  creatorPreview: false,
  snackConfig: {
    open: false,
    message: "",
    severity: "success",
    timeout: 4000,
    bgColor: 'var(--brand-color)',
  },
  incomeData: {},
};

export const influencerSlice = createSlice({
  name: "influencerSlice",
  initialState,
  reducers: {
    resetInfluencerSlice(state) {
      state = initialState;
    },
    setDisplayName(state, action) {
      state.displayName = action.payload;
    },
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setInfluencerInfo(state, action) {
      state.influencerInfo = structuredClone(action.payload);
    },
    setInfluencerInfoFirst(state, action) {
      state.influencerInfoFirst = structuredClone(action.payload);
    },
    setSubscriptionSubTab(state, action) {
      state.subscriptionSubTab = action.payload;
    },
    setCreatorPreview(state, action) {
      state.creatorPreview = action.payload;
    },
    setSnackConfig(state, action) {
      state.snackConfig = {...action.payload};
    },
    setIncomeData(state, action) {
      state.incomeData = structuredClone(action.payload);
    },
  },
});

export const {
  resetInfluencerSlice,
  setDisplayName,
  setCurrentTab,
  setInfluencerInfo,
  setInfluencerInfoFirst,
  setSubscriptionSubTab,
  setCreatorPreview,
  setSnackConfig,
  setIncomeData,
} = influencerSlice.actions;

export default influencerSlice.reducer;

export const selectInfluencer = state => state.influencerSlice; 
